<template>
  <div class="billManagement merch">
    <div class="sign" style="margin-bottom: 20px"></div>
    <!-- 搜索框 -->
    <el-form ref="queryForm" :model="queryParams" :inline="true" class="rela">
      <el-form-item prop="billNumber">
        <el-input
          v-model="queryParams.billNumber"
          placeholder="账单编号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="billCode">
        <el-input
          v-model="queryParams.billCode"
          placeholder="交易流水号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="supplierName">
        <el-input
          v-model="queryParams.supplierName"
          placeholder="发货商家"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item prop="payBy">
        <el-select
          v-model="queryParams.status"
          placeholder="结算状态"
          clearable
          size="small"
        >
          <el-option
            v-for="(item, index) in Paymentlist"
            :key="index"
            :label="item.Name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <div style="margin: 1px" />
      <el-form-item>
        <el-date-picker
          v-model="datelist"
          type="datetimerange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button @click="resetQueryList">重置</el-button>
      </el-form-item>
      <!-- <div class="abso" style="right: 10px; bottom: 5px">
        <el-button type="primary" v-if="allJurisdiction.billManagement126"
          >批量结算</el-button
        >
      </div> -->
    </el-form>

    <!-- 表格数据 -->
    <div class="dataForm">
      <el-table
        v-loading="loading"
        :data="OrderqueryList"
        border
        style="width: 100%"
        max-height="450"
        ref="tableder"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
          v-if="OrderqueryList.length != 0"
        />
        <el-table-column align="center" label="序号" type="index" width="70">
          <template slot-scope="scope">
            <p>{{ scope.row.index }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="账单生成时间"
          align="center"
          width="200"
        />
        <el-table-column
          prop="billNumber"
          label="账单编号"
          align="center"
          width="200"
          show-overflow-tooltip
        />
        <el-table-column label="交易流水号" width="250" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.billCode }}</div>
            <div class="futle">{{ scope.row.orderCreateTime }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="supplierName"
          label="发货供应商"
          align="center"
          width="200"
          show-overflow-tooltip
        />
        <el-table-column prop="billAmount" label="账单金额" align="center" />
        <el-table-column prop="status" label="结算状态" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.status == 0 ? "未结算" : "已结算" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="estimatedSettlementTime"
          label="预计结算日期"
          align="center"
          width="150"
        />
        <el-table-column label="操作" align="center" width="300">
          <template slot-scope="scope">
            <span
              class="btsed"
              @click="handldetail(scope.row)"
              v-if="allJurisdiction.billManagement128"
              >详情</span
            >
            <!-- <span
              class="btsed"
              @click="handleCommod(scope.row)"
              v-if="allJurisdiction.billManagement127"
              >一键结算</span
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="merch-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="queryParams.size"
        :current-page="queryParams.pageNumber"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const settlement = createNamespacedHelpers("settlement");
export default {
  data() {
    return {
      loading: false,
      OrderqueryList: [],
      total: 1,
      // 搜索栏
      queryParams: {
        page: 1,
        size: 10,
        billNumber: "", // 账单编号
        billCode: "", // 交易流水号
        supplierName: "", //发货商家
        status: "", //结算状态
        startTime: "", //开始时间
        endTime: "", //结束时间
      },
      searchdata: "",
      datelist: [],
      ids: [],
      // 支付方式
      Paymentlist: [
        {
          Name: "已结算",
          id: 1,
        },
        {
          Name: "未结算",
          id: 0,
        },
      ],
    };
  },
  created() {
    let router=this.$router.history.current.path.substr(1)
    if(this.detailsSeach[router]){
      this.queryParams=this.detailsSeach[router]
      this.datelist=this.detailsSeach[router].datelist
    }
    this.getList();
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction",'detailsSeach']),
  },
  methods: {
    ...settlement.mapActions(["getsettlementBillListList"]),
    ...commonIndex.mapMutations(['changeSeach']),
    /** 查询列表数据 */
    async getList() {
      this.loading = true;
      if (this.datelist) {
        this.queryParams.startTime = this.datelist[0];
        this.queryParams.endTime = this.datelist[1];
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      let res = await this.getsettlementBillListList(this.queryParams);

      let router=this.$router.history.current.path.substr(1)
        let save={...this.queryParams}
        save.datelist=this.datelist
        save.router=router
        this.changeSeach(save)

      res.data.list.forEach((v, i) => {
        let index = (res.data.pageNumber - 1) * res.data.pageSize + i + 1;
        this.$set(v, "index", index);
      });
      this.queryParams.pageNumber=res.data.pageNumber
      this.OrderqueryList = res.data.list;
      this.total = res.data.totalRecords;
      this.loading = false;
    },
    // 分页
    handleCurrentChange(item) {
      this.queryParams.page = item;
      this.getList();
    },
    /** 搜索按键操作 */
    handleQuery() {

      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按键操作 */
    resetQueryList() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.datelist = [];
      this.getList();
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = [];
      for (let i in selection) {
        this.ids.push(selection[i]);
      }
      this.batch = !selection.length;
    },
    /** 详情 */
    handldetail(row) {
      this.$router.push({
        path: "/billManagementDetails",
        query: {
          id: row.id,
        },
      });
      localStorage.setItem("mangeitem", JSON.stringify(row));
    },
    /** 一键结算 */
    handleCommod(row) {

    },
  },
};
</script>
<style lang="scss" scoped>
.defaultStyle {
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
.opp {
  color: #fff;
  border-radius: 5px;
  background-color: rgb(26, 188, 156);
}
.el-form-item {
  margin-bottom: 5px;
}
</style>
